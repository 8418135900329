<template>
  <v-navigation-drawer
    app
    :dark="true"
    :drawer="true"
    :color="drawerColor"
    :width="drawerWidth"
    :permanent="!isMobile"
    :temporary="isMobile"
    v-model="localDrawer"
    elevation="10"
    class="sidebar-container"
  >
    <!-- Logo Section -->

    <div  class="sidebar-logo">
      <img v-if="!gameDetails" :src="logoSrc" contain height="auto" width="135" />
      <img v-if="gameDetails"
          src="@/assets/thingsToDo/harlemlogo.png"
          contain height="auto" width="135"
        />
      <div v-if="campaignData" class="campaign-meta">
        <div class="campaign-name">{{ organizationData.name }}</div>
        <v-chip
          :class="
            campaignData && campaignData.campaign_status
              ? campaignData.campaign_status.toUpperCase()
              : ''
          "
        >
          <span>{{ campaignData.campaign_status }} Campaign</span>
        </v-chip>
        <div class="campaignDates">
          {{ campaignData.start_date }} to {{ campaignData.end_date }}
        </div>
        <div class="campaignId">{{ campaignData.campaign_id }}</div>
      </div>
      <!-- gamedetails  -->
      <div v-if="gameDetails" class="campaign-meta">
        <div class="campaign-name">{{ gameDetails.organization }}</div>
        
          <span>{{ gameDetails.team_name }} Team</span>
        <div class="campaignDates">
          {{ gameDetails.game_date_str }} to {{ gameDetails.start_time_str }}
        </div>
        <div class="campaignId">{{ gameDetails.game_type }}</div>
      </div>
    </div>
    <!-- Nav Links -->
    <v-list navdense class="pa-0 sidebar-list">
      <v-list-item-group v-model="localSelectedItem">
        <v-list-item
          v-for="(item, i) in navLinks"
          :key="i"
          :to="{ name: item.to, query: queryParams }"
          class="sidebar-menu-item"
          active-class="active-class"
          @click="$emit('tab-change', item.heading)"
        >
          <template v-slot:default="{ active }">
            <div class="sidebar-item-content">
              <img
                class="sidebar-icon"
                :src="
                  getImgUrl(
                    active ? `${item.icon}Active.svg` : `${item.icon}.svg`
                  )
                "
              />
              <v-list-item-title class="sidebar-item-text">
                {{ item.title }}
              </v-list-item-title>
            </div>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    drawer: Boolean,
    navLinks: Array,
    selectedItem: Number,
    isMobile: Boolean,
    drawerColor: {
      type: String,
      default: "#2C1963",
    },
    drawerWidth: {
      type: String,
      default: "315px",
    },
    logoSrc: {
      type: String,
      default: require("@/assets/Auth/wizfilogo1.png"),
    },
    campaignName: {
      type: String,
      default: "",
    },
    organizationData: Object,
    gameDetails: Object,
    campaignData: Object,
  },
  methods: {
    getImgUrl(img) {
      // Dynamically require SVGs from your /assets/Navigation folder
      return require("@/assets/Navigation/" + img);
    },
  },
  mounted() {
    console.log("campaignData", this.gameDetails);
  },
  computed: {
    localSelectedItem: {
      get() {
        return this.selectedItem;
      },
      set(val) {
        this.$emit("update:selectedItem", val);
      },
    },
    localDrawer: {
      get() {
        return this.drawer;
      },
      set(val) {
        this.$emit("update:drawer", val);
      },
    },
    queryParams() {
      return this.$route.query;
    },
  },
};
</script>

<style scoped>
/* Root Variables (optional) */
:root {
  --sidebar-bg-color: #2c1963; /* Default background color */
  --sidebar-hover-bg: rgba(255, 255, 255, 0.05);
  --sidebar-active-bg: rgba(255, 255, 255, 0.1);
  --text-color: #ffffff;
}

/* Container for the entire navigation drawer */
.sidebar-container {
  box-shadow: none;
  /* If you want a subtle shadow: box-shadow: 2px 0 6px rgba(0,0,0,0.3); */
}
.campaign-meta {
  margin-top: 10px;
  text-align: center;
}

.campaign-name {
  font-weight: 700;
  font-size: 20px;
  font-family: "Roboto Slab", serif;
  color: black;
}

.campaign-dates {
  font-size: 12px;
  font-weight: 400;
  color: #ccc;
}
.sidebar-menu-item {
  justify-content: center;
  transition: background-color 0.1 ease-out;
  padding-top: 3px;
}
.sidebar-logo {
  /* margin: 2rem auto 1rem auto; */
  place-items: center;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  color: black;
  box-shadow: inset 0 5px 10px rgb(197 190 215), 0 5px 19px rgb(12 4 33);
}

.sidebar-logo img {
  object-fit: cover;
}
.v-item-group {
    padding: 12px;
    flex: 0 1 auto;
    position: relative;
    max-width: 100%;
    transition: 0.3scubic-bezier(0.25, 0.8, 0.5, 1);
}
.sidebar-list a {
  text-decoration: none;
  color: inherit;
  transition: background-color 0.5s ease-out;
}
.sidebar-list a {
  transition: transform 0.2s ease-in-out;
  text-decoration: none;
}

.sidebar-list:hover a {
  text-decoration: none;
  margin-top: 0;
}

/* Remove any pseudo-element underline effects */
.v-list-item--link:before {
  width: 100%;
  border-radius: 0 !important;
  content: none; /* remove any pseudo-content if it's used for underlines */
}

span.v-chip.ONGOING {
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
}
span.v-chip.UPCOMING {
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
}
span.v-chip.COMPLETED {
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  line-height: 315%;
  color: #ff5151;
  letter-spacing: 0px;
}

/* Each list item container */
.sidebar-menu-item {
  justify-content: center;
  transition: background-color 0.2s ease;
}

/* Layout for the link content (icon + text) */
.sidebar-item-content {
  width: 70%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.campaignDates {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 145%;
  color: #1d1d1d;
  margin-top: 5px;
}
.sidebar-list a:hover {
  transform: scale(1.5);
  color: #ffba00 !important;
  margin-top: 0;
}

/* Icon styling */
.sidebar-icon {
  max-width: 15px;
  margin-bottom: -2px;
}
.v-list--nav.v-list--dense .v-list-item:not(:last-child):not(:only-child),
.v-list--nav .v-list-item--dense:not(:last-child):not(:only-child),
.v-list--rounded.v-list--dense .v-list-item:not(:last-child):not(:only-child),
.v-list--rounded .v-list-item--dense:not(:last-child):not(:only-child) {
  margin-bottom: 4px;
  color: white;
  --sidebar-active-border: #ffba00 solid 100px;
}
/* Text styling */
.sidebar-item-text {
  font-family: "Roboto Slab", serif !important;
  font-weight: 700 !important;
  font-size: 13px;
  margin-left: 6px;
  color: #9387b6; /* Non-active text color */
  transition: color 0.2s ease;
}

/* Active class: highlight the link */
.active-class {
  border-right: 6px solid var(--sidebar-active-border);
  background-color: var(--sidebar-active-bg);
  border-radius: 0 !important;
  transform: scale(1.5);
  color: #ffba00 !important;
  margin-top: 0;
}

/* Ensures active text color becomes white */
.active-class >>> .v-list-item__title {
  color: var(--text-color) !important;
}

/* Optional hover effect */
.v-list-item:not(.active-class):hover {
  background-color: var(--sidebar-hover-bg) !important;
}
</style>
