<template>
  <v-app-bar color="#FFFFFF" class="pr-5 pl-3" app>
    <!-- Mobile Nav Icon (for toggling drawer) -->
    <v-app-bar-nav-icon v-if="isMobile" @click="$emit('toggle-drawer')" />

    <!-- Back Button (if needed) -->
    <v-btn text class="px-0" v-if="backButtonVisible" @click="$emit('go-back')">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <!-- Toolbar Title -->
    <v-toolbar-title class="mainToolbar-title">{{ barTitle }}</v-toolbar-title>
    <v-spacer></v-spacer>

    <!-- Switch Campaign Button -->
 
    <v-btn
      v-if="campaignData && campaignData.no_of_campaign > 1 && campaignData.campaign_type === 'school'"
      depressed
      color="#F2F2F2"
      class="switchCampaignBtn mr-5"
      @click="$emit('toggle-switch-campaign-modal')"
    >
      <v-icon color="#6B6B6B" size="20" left>mdi-repeat</v-icon>
      <span class="mr-2" style="color: #383838">Switch Campaign</span>
      <v-icon color="#6B6B6B" size="25" right>mdi-chevron-down</v-icon>
    </v-btn>
      <v-btn
          depressed
          color="#F2F2F2"
          class="switchCampaignBtn mr-5"
          @click="toggleSelectGameModal({ show: true })"
          v-if="gameDetails && (gameDetails.same_game_count>1)"
        >
          <v-icon color="#6B6B6B" size="20" left>mdi-repeat</v-icon>
          <span class="mr-2" style="color: #383838">Switch Games</span>
          <v-icon color="#6B6B6B" size="25" right>mdi-chevron-down</v-icon>
        </v-btn>
    <!-- Edit Campaign Button -->
    <v-btn
          outlined
          depressed
          color="#38227A"
          class="switchCampaignBtn mr-5 rounded-lg"
          v-if="
            userTeam === 'admin' &&
            ($route.matched[0].path.substring(1) === !'teacher' ||
              $route.matched[0].path.substring(1) === !'pe-teacher')
          "
          @click="editCampaign"
        />
    <v-btn
      outlined
      depressed
      color="#38227A"
      class="switchCampaignBtn mr-5 rounded-lg"
      v-if="showEditCampaign"
      @click="$emit('edit-campaign')"
    >
      <v-icon class="mt-1" size="18" left>mdi-border-color</v-icon>
      Edit Campaign
    </v-btn>

    <!-- Upload Documents Button for Desktop -->
    <v-btn
      color="#38227A"
      dark
      class="px-12 text-capitalize"
      height="45px"
      width="155px"
      v-if="(campaignData) && !isMobile"
      style="border-radius: 10px"
      @click="$emit('upload-documents')"
    >
      <span>Upload Documents</span>
    </v-btn>

    <!-- Upload Documents Option for Mobile via Menu -->
    <v-menu offset-y v-if="(campaignData) && isMobile">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" class="mobile-menu">mdi-menu</v-icon>
      </template>
      <v-list>
        <v-list-item-group active-class="active-class">
          <v-list-item @click="$emit('upload-documents')">
            <v-list-item-title> Upload Documents </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <!-- User Avatar & Dropdown Menu -->
    <v-menu bottom min-width="200px" rounded offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon x-large v-on="on">
          <v-avatar color="rgba(255, 81, 81, 0.12)" size="38">
            <span class="user-initials-text">{{ user.initials }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list-item-content class="justify-center">
          <div class="mx-auto text-center">
            <v-avatar color="rgba(255, 81, 81, 0.12)">
              <span class="user-initials-text">{{ user.initials }}</span>
            </v-avatar>
            <h3>{{ user.user_name }}</h3>
            <p class="text-caption mt-1">{{ user.user_email }}</p>
            <v-divider class="my-3"></v-divider>
            <v-btn depressed rounded @click="$emit('logout')" text>
              Logout
            </v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
  props: {
    barTitle: {
      type: String,
      default: ""
    },
    user: {
      type: Object,
      default: () => ({})
    },
    campaignData: Object,
    gameDetails:Object,
    showSwitchCampaignButton: {
      type: Boolean,
      default: false
    },
    userTeam: {
      type: String,
      default: ""
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    backButtonVisible: {
      type: Boolean,
      default: false
    },
    showEditCampaign: {
      type: Boolean,
      default: false
    }
  },
  mounted(){
    console.log("campaignDat....",this.campaignData);
  },
  watch: {
  campaignData(newVal) {
    this.campaignData=newVal; 
    console.log("watch campaignData", newVal);
  }
}

};
</script>

<style scoped>
.mainToolbar-title {
  font-family: "LubalGraph Bd BT", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 315%;
  color: #2d2d2d;
  white-space: nowrap;
}
.user-initials-text {
  font-family: Lato, sans-serif;
  font-weight: 800;
  font-size: 17px;
  color: #ff5151;
}
</style>
